import { forwardRef } from "react";
import { isFunction } from "formik";
import Renderer from "../Renderer";

const Conditional = ({
    when,
    then,
    otherwise,
    children,
    Layout = LayoutStub,
    ...restProps
}, ref) => {
    const cond = () =>
        isFunction(when)
            ? when()
            : when;

    return (
        <Layout
            {...restProps}
            ref={ref}
        >
            {cond()
                ? (
                    <Renderer
                        {...restProps}
                    >
                        {then}
                    </Renderer>
                )
                : (
                    <Renderer
                        {...restProps}
                    >
                        {otherwise || children}
                    </Renderer>
                )}
        </Layout>
    );
};

export default forwardRef(Conditional);

function LayoutStub({ children }) {
    return (
        <>
            {children}
        </>
    );
}
