import { isValidElement } from "react";
import { isFunction } from "formik";

const Renderer = ({ children, ...restProps }) => {
    if (!children) {
        return null;
    }

    if (isFunction(children)) {
        return children(restProps);
    }

    const ComponentOrSlot = children;

    return (
        isValidElement(ComponentOrSlot)
            ? ComponentOrSlot
            : <ComponentOrSlot {...restProps} />
    );
};

export default Renderer;
